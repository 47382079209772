<template>
  <div class="functional">
    <div class="title">功能特点</div>
    <div class="main-box">
      <div class="left">
        <div v-for="(item,index) in list" :key="index" class="item" :class="{active:active_index===index}" @click="cgActiveIndex(index)">
          <img class="icon" :src="require(`@/assets/icons/productCenter/${item.icon}`)" alt="功能特点" srcset="">
          <div>
            <div class="tt">{{item.title}}</div>
            <div class="intro">{{item.intro}}</div>
          </div>
          <div class="progress"></div>
        </div>
      </div>
      <div class="image-box">
        <img :src="require(`@/assets/image/productCenter/${list[active_index].image}`)" alt="悬浮icon" srcset="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['list'],
  data () {
    return {
      active_index: 0,
      interval: -1
    }
  },
  mounted () {
    this.cgActiveIndex(0)
  },
  methods: {
    cgActiveIndex (index) {
      clearInterval(this.interval)
      this.active_index = index
      this.interval = setInterval(() => {
        if (this.active_index < this.list.length - 1) {
          this.active_index++
        } else {
          this.active_index = 0
        }
      }, 5000)
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes progress {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.functional {
  padding-top: 160px;
  padding-bottom: 112px;
  .title {
    font-size: 36px;
    color: #303b50;
    line-height: 48px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 80px;
  }
  .main-box {
    display: flex;
    justify-content: space-between;
    border: 1px solid #e1e2e6;
    .left {
      padding: 40px 32px 40px 24px;
      border-right: 1px solid #e1e2e6;
      width: 400px;
      flex-shrink: 0;
      color: #303b50;
      & > div {
        display: flex;
        padding-bottom: 24px;
        position: relative;
        margin-bottom: 24px;
        cursor: pointer;
        &:hover {
          .tt {
            color: $primaryColor;
          }
        }
        .progress {
          width: 100%;
          height: 2px;
          background: #0059ff;
          position: absolute;
          left: 0;
          bottom: 0;
          display: none;
        }
        &.active {
          .tt {
            color: $primaryColor;
          }
          .progress {
            display: block;
            animation: progress 5s linear;
          }
        }
        .icon {
          width: 34px;
          height: 32px;
          margin-bottom: 26px;
          margin-right: 30px;
        }
        .tt {
          font-size: 18px;
          line-height: 32px;
          margin-bottom: 12px;
        }
        .intro {
          font-size: 14px;
          color: #333333;
          line-height: 24px;
        }
      }
    }
    .image-box {
      position: relative;
      width: 100%;
      img {
        width: 744px;
        height: 464px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
</style>
